.dropdown-cell-group {
	min-width: 200px;
}

.dropdown-cell-element {
	min-width: 150px;
	text-align: left;
}

.dataTable_wrapper table tr td {
	max-width: 200px !important;
	word-wrap: break-word;
}

div.input-group > input {
    border: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

div.form-group h3 {
	margin-top: 0;
	margin-bottom: 0;
}

div.form-group p,
div.form-group ul {
	padding-top: 7px;
	margin-bottom: 0;
}

div.form-group li {
    margin-bottom: 0.3rem;
}

div.form-group select:not(:focus)[multiple],
div.form-group textarea:not(:focus) {
    border: 1px solid #ddd;
    border-radius: 4px;
}

form.cstm-form-vwetstats .form-group {
    border: 1px solid #ccc;
    padding: 5px;
}

form.cstm-form-vwetstats h4 {
    margin-top: 3.5rem;
    border-top: 2px solid #333;
    padding: 5px;
}

form.cstm-form-vwetstats p {
    padding-top: 0;
    margin-bottom: 5px;
}

form.cstm-form-vwetstats > button {
    margin-bottom: 10px;
}

ul.cstm-list-vwetstats {
    margin-left: 0;
}

ul.cstm-list-vwetstats > li {
    margin-left: 4.5rem;
}

ul.cstm-list-vwetstats > li:first-child {
    margin-left: 0;
    list-style-type: none;
    font-size: 2rem;
}

.cstm-page-header-btn {
    margin-top: 50px;
}

a.disabled {
    pointer-events: none;
    cursor: default;
    color: #777;
}

.cstm-activity-log-title {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 5px;
    line-height: 1;
}

img.cstm-country-flag {
	max-height: 30px;
	width: auto;
}

.cstm-loading {
	text-align: center;
	font-size: 2rem;
	margin: 1rem;
}

.cstm-control-label-disabled {
	cursor: not-allowed;
	color: #777;
}

.cstm-control-label-disabled::after {
	content: "(cannot edit)";
	display: block;
	color: #777;
	font-size: 1rem;
	font-style: italic;
	font-weight: 400;
}

.cstm-control-label-small {
	display: block;
	color: #777;
	font-size: 1rem;
	font-weight: 400;
}

div.cstm-disabled-field {
	display: block;
	padding: 0.2rem 1rem;
	border: 1px solid #ddd;
	border-radius: 3px;
	background: #F5F5F5;
	cursor: not-allowed;
	color: #777;
}

div.cstm-disabled-field > p,
div.cstm-disabled-field > p.lead {
	margin: 0;
	padding: 0;
}

.cstm-form-section-tabset .tab-content {
	padding: 1rem;
	border: 1px solid #ddd;
	border-top: 0;
}

hr.cstm-form-section-separator {
	margin: 2rem 0;
	border-top: 2rem solid #ddd;

	+ div.cstm-form-section-heading h3 {
		margin-top: -1.5rem;
	}
}

div.cstm-form-section-heading {
	margin-bottom: 2rem;
	border-bottom: 1px solid #F5F5F5;
}

div.cstm-form-section-heading h3 {
	margin-bottom: 0.5rem;
	text-align: right;
	font-weight: 200;
}

div.cstm-prodtech-item {
	position: relative;
	margin-bottom: 1rem;
	padding: 1rem 2rem;
	border: 1px solid #ccc;
	border-radius: 3px;
}

div.cstm-prodtech-item .form-group label {
	margin-bottom: 0;
	font-size: 1.1rem;
	font-weight: 600;
}

div.cstm-prodtech-item .form-group:last-child {
	margin-bottom: 0;
}

button.cstm-close {
	z-index: 1;
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	display: block;
	padding: 0;
	border: 0;
	background: 0 0;
	opacity: 0.3;
	color: #000;
	font-size: 2rem;
	font-weight: 600;
	line-height: 1;
	cursor: pointer;
	overflow: hidden;
}

button.cstm-close:hover,
button.cstm-close:active,
button.cstm-close:focus {
	opacity: 0.7;
}

.form-horizontal .radio.cstm-radio-table {
	display: block;
}

.form-horizontal .radio.cstm-radio-table input[type=radio] {
	top: 0;
}

.link-dialog {
	.modal-body {
		padding: 40px;
	}
}
